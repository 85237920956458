@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap');

html {
  font-family: 'Open Sans', sans-serif;
}

h2 {
  color: $color-primary;
  font-size: 5rem;
  font-weight: 800;
  margin-bottom: 1rem;

  @media screen and (max-width: 650px){
    font-size: 2.4rem;
  }
}

h3 {
  color: white;
  font-size: 4rem;
  font-weight: 800;

  @media screen and (max-width: 800px){
    font-size: 3rem;
  }
  @media screen and (max-width: 650px){
    font-size: 2rem;
  }
}

h4 {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;

  @media screen and (max-width: 650px){
    font-size: 1rem;
  }
}