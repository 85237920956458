.splash {
  display: flex;
  min-height: 105vh;
  height: 90rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // animation: splash-height 4s 3s ease-out forwards;

  @media screen and (max-width: 650px) {
    height: 50rem;
  }

  &__animation {
    margin-left: 8rem;
    margin-top: -19rem;

    @media screen and (max-width: 650px) {
      margin-left: 11rem;
    }

    .letter {
      margin-top: 10rem;
      width: 21rem;
      height: 17rem;
      overflow: visible;
      margin-left: -10rem;

      @media screen and (max-width: 650px) {
        width: 15rem;
        height: 8rem;
        margin-left: -10rem;
      }
    }

    .icon {
      width: 11rem;
      height: 11rem;
      overflow: visible;
      cursor: pointer;

      @media screen and (max-width: 1100px) {
        width: 8rem;
        height: 8rem;
      }

      @media screen and (max-width: 650px) {
        width: 6rem;
        height: 6rem;
      }
    }

    .letter-l {
      transform: translate(-0.9rem, -1rem);

      @media screen and (max-width: 650px) {
        transform: translate(-0.4rem, -0.5rem);
      }
    }

    .letter-w {
      height: 17.9rem;
      transform: translate(-1.6rem, -1.8rem);

      @media screen and (max-width: 650px) {
        height: 8.2rem;
        transform: translate(-0.7rem, -1.1rem);
      }
    }

    .letter-i {
      height: 17.2rem;
      transform: translate(-2rem, -3.1rem);

      @media screen and (max-width: 650px) {
        height: 7.8rem;
        transform: translate(-1rem, -1.7rem);
      }
    }

    .letter-n {
      height: 18rem;
      transform: translate(-5.2rem, -3.4rem);

      @media screen and (max-width: 650px) {
        height: 8rem;
        transform: translate(-2.4rem, -1.9rem);
      }
    }

    #box-purple {
      animation: purpleBox 0.3s 0.6s ease-out forwards;
      transform-origin: 50% 50%;
      opacity: 0;
    }

    #box-red {
      animation: redBox 0.5s ease-out forwards;
      transform-origin: 50% 50%;
      opacity: 0;
    }

    #box-blue {
      animation: blueBox 0.5s 0.25s ease-out forwards;
      transform-origin: 50% 50%;
      opacity: 0;
    }

    #box-shadow {
      animation: fadeIn 0.5s 0.6s ease-out forwards;
      transform-origin: 50% 50%;
      opacity: 0;
    }

    #box-innerpink {
      animation: redBox 0.5s 0.25s ease-out forwards;
      transform-origin: 50% 50%;
      opacity: 0;
    }

    #box-pink {
      animation: redBox 0.5s 0.25s ease-out forwards;
      transform-origin: 50% 50%;
      opacity: 0;
    }

    #icon {
      animation: fadeIn 0.5s 1.1s ease-out forwards;
      opacity: 0;
    }
    /* L Specific Timing*/
    .letter-l #box-purple {
      animation: purpleBox 0.3s 0.7s ease-out forwards;
    }

    .letter-l #box-red {
      animation: redBox 0.5s 0.1s ease-out forwards;
    }

    .letter-l #box-blue {
      animation: blueBox 0.5s 0.35s ease-out forwards;
    }

    .letter-l #box-shadow {
      animation: fadeIn 0.5s 0.7s ease-out forwards;
    }

    .letter-l #box-innerpink {
      animation: redBox 0.5s 0.35s ease-out forwards;
    }

    .letter-l #box-pink {
      animation: redBox 0.5s 0.35s ease-out forwards;
    }

    /* W Specific Timing*/
    .letter-w #box-purple {
      animation: purpleBox 0.3s 0.8s ease-out forwards;
    }

    .letter-w #box-red {
      animation: redBox 0.5s 0.2s ease-out forwards;
    }

    .letter-w #box-blue {
      animation: blueBox 0.5s 0.45s ease-out forwards;
    }

    .letter-w #box-shadow {
      animation: fadeIn 0.5s 0.8s ease-out forwards;
    }

    .letter-w #box-innerpink {
      animation: redBox 0.5s 0.45s ease-out forwards;
    }

    .letter-w #box-pink {
      animation: redBox 0.5s 0.45s ease-out forwards;
    }

    /* I Specific Timing*/
    .letter-i #box-purple {
      animation: purpleBox 0.3s 0.9s ease-out forwards;
    }

    .letter-i #box-red {
      animation: redBox 0.5s 0.3s ease-out forwards;
    }

    .letter-i #box-blue {
      animation: blueBox 0.5s 0.55s ease-out forwards;
    }

    .letter-i #box-shadow {
      animation: fadeIn 0.5s 0.9s ease-out forwards;
    }

    .letter-i #box-innerpink {
      animation: redBox 0.5s 0.55s ease-out forwards;
    }

    .letter-i #box-pink {
      animation: redBox 0.5s 0.55s ease-out forwards;
    }

    /* N Specific Timing*/
    .letter-n #box-purple {
      animation: purpleBox 0.3s 1s ease-out forwards;
    }

    .letter-n #box-red {
      animation: redBox 0.5s 0.4s ease-out forwards;
    }

    .letter-n #box-blue {
      animation: blueBox 0.5s 0.65s ease-out forwards;
    }

    .letter-n #box-shadow {
      animation: fadeIn 0.5s 1s ease-out forwards;
    }

    .letter-n #box-innerpink {
      animation: redBox 0.5s 0.365s ease-out forwards;
    }

    .letter-n #box-pink {
      animation: redBox 0.5s 0.65s ease-out forwards;
    }
  }

  .divider-line {
    height: 0;
    width: 0.3rem;
    background-color: $color-secondary;
    display: inline-block;
    position: absolute;
    margin-top: 5rem;
    animation: dividerLine 0.3s 1s ease-out forwards;
    margin-left: -2.5rem;

    @media screen and (max-width: 1100px) {
      display: none;
    }
  }

  &__icons {
    display: inline-block;

    .icon-github {
      display: inline-block;
      margin-left: 10rem;
      transform: translate(-4.5rem, -6rem) rotateY(90deg);
      animation: cardflip-github 0.5s 1.3s ease-out forwards;
    }

    .icon-linkedin {
      margin-left: 1rem;
      transform-style: preserve-3d;
      transform: translate(-4.5rem, -7.5rem) rotateY(90deg);
      animation: cardflip-linkedin 0.5s 1.5s ease-out forwards;
    }

    .icon-email {
      margin-left: 10px;
      transform: translate(-4.5rem, -8.9rem);
      transform: translate(-4.5rem, -8.9rem) rotateY(90deg);
      animation: cardflip-email 0.5s 1.7s ease-out forwards;
    }

    @media screen and (max-width: 1100px) {
      margin-top: 10rem;
      margin-left: 3.5rem;
      width: 100%;

      .icon-linkedin {
        animation: cardflip-linkedin-small 0.5s 1.5s ease-out forwards;
      }

      .icon-email {
        animation: cardflip-email-small 0.5s 1.7s ease-out forwards;
      }
    }

    @media screen and (max-width: 650px) {
      margin-top: 8rem;
      margin-left: -9rem;
      width: 125%;

      .icon-linkedin {
        animation: cardflip-linkedin-xsmall 0.5s 1.5s ease-out forwards;
      }

      .icon-email {
        animation: cardflip-email-xsmall 0.5s 1.7s ease-out forwards;
      }
    }
  }

  &__subtitle {
    color: $color-primary;
    font-weight: bold;
    letter-spacing: 0.6rem;
    font-size: 2rem;
    text-transform: uppercase;
    transform: rotate(-5deg);
    margin-top: -1rem;
    margin-left: -44rem;
    opacity: 0;
    animation: subtitle 0.6s 2s linear forwards;
    font-weight: 700;
    margin-bottom: 0;

    @media screen and (max-width: 1100px) {
      margin-left: 0;
      margin-top: -21rem;
    }

    @media screen and (max-width: 650px) {
      margin-left: -5.6rem;
      margin-top: -16rem;
      font-size: 1rem;
      letter-spacing: 0.2rem;
      animation: subtitle-xsmall 0.6s 2s linear forwards;
    }
  }
}
