.about {
  position: relative;
  width: 100rem;
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 10rem;
  margin-top: -25rem;
  // opacity: 0;
  // animation: fadeIn 0.5s 2.75s ease-out forwards;

  h2 {
    opacity: 0;
  }

  p {
    text-align: left;
    opacity: 0;
    padding-top: 4rem;
    color: white;
    font-weight: 400;
    font-size: 2rem;

    @media screen and (max-width: 650px) {
      max-width: 80%;
      margin: 0 auto;
      font-size: 1.6rem;
    }
  }

  &--active {
    h2 {
      opacity: 1;
      transition: opacity 2s;
    }

    .underline {
      width: 100%;
      transition: width 1s;

      @media screen and (max-width: 650px) {
        width: 80%;
      }
    }

    p {
      opacity: 1;
      transition: opacity 2s;

      a {
        color: white;
      }
    }
  }
}
