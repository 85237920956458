html {
  scroll-behavior: smooth;
}

.App {
  background-color: $color-background;
  text-align: center;
}

.underline {
  height: .3rem;
  width: 0%;
  background-color: $color-secondary;
  display: flex;
  // margin-left: calc((100% - 100rem) / 2);
  margin-bottom: 3rem;
  position: absolute;
  left: 0;

  @media screen and (max-width: 650px){
    width: 0%;
    left: 10%;
  }
}

