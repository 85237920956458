@keyframes splash-height {
  0% {
    min-height: 100vh;
  }
  100% {
    min-height: 0vh;
  }
}

@keyframes purpleBox {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes redBox {
  0% {
    transform: translateX(-1rem);
  }
  1% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes blueBox {
  0% {
    transform: translateX(-1rem);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dividerLine {
  0% {
    height: 0;
  }
  100% {
    height: 27rem;
  }
}

@keyframes dividerLine-Small {
  0% {
    width: 0;
  }
  100% {
    width: 49rem;
  }
}

@keyframes cardflip-github {
  0% {
    transform: translate(-4.5rem, -6rem) rotateY(90deg);
  }
  100% {
    transform: translate(-4.5rem, -6rem) rotateY(0deg);
  }
}

@keyframes cardflip-linkedin {
  0% {
    transform: translate(-4.5rem, -7.5rem) rotateY(90deg);
  }
  100% {
    transform: translate(-4.5rem, -7.5rem) rotateY(0deg);
  }
}

@keyframes cardflip-linkedin-small {
  0% {
    transform: translate(-4.5rem, -7.1rem) rotateY(90deg);
  }
  100% {
    transform: translate(-4.5rem, -7.1rem) rotateY(0deg);
  }
}

@keyframes cardflip-linkedin-xsmall {
  0% {
    transform: translate(-5rem, -6.8rem) rotateY(90deg);
  }
  100% {
    transform: translate(-5rem, -6.8rem) rotateY(0deg);
  }
}

@keyframes cardflip-email {
  0% {
    transform: translate(-4.5rem, -8.9rem) rotateY(90deg);
  }
  100% {
    transform: translate(-4.5rem, -8.9rem) rotateY(0deg);
  }
}

@keyframes cardflip-email-small {
  0% {
    transform: translate(-4.5rem, -8.2rem) rotateY(90deg);
  }
  100% {
    transform: translate(-4.5rem, -8.2rem) rotateY(0deg);
  }
}

@keyframes cardflip-email-xsmall {
  0% {
    transform: translate(-5.5rem, -7.6rem) rotateY(90deg);
  }
  100% {
    transform: translate(-5.5rem, -7.6rem) rotateY(0deg);
  }
}

@keyframes subtitle {
  0% {
    opacity: 0;
    transform: rotate(-5deg) translateY(5px);
  }
  100% {
    opacity: 1;
    transform: rotate(-5deg) translateY(0);
  }
}

@keyframes subtitle-xsmall {
  0% {
    opacity: 0;
    transform: rotate(-6deg) translateY(5px);
  }
  100% {
    opacity: 1;
    transform: rotate(-6deg) translateY(0);
  }
}