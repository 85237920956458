@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*Sanity-ize scss*/
html {
  font-size: 62.5%;
  /* Now 10px = 1rem! */ }

body {
  font-size: 16px;
  /* px fallback */
  font-size: 1.6rem;
  /* default font-size for document */
  line-height: 1.5;
  /* a nice line-height */ }

html {
  font-family: 'Open Sans', sans-serif; }

h2 {
  color: #FFC0D7;
  font-size: 5rem;
  font-weight: 800;
  margin-bottom: 1rem; }
  @media screen and (max-width: 650px) {
    h2 {
      font-size: 2.4rem; } }

h3 {
  color: white;
  font-size: 4rem;
  font-weight: 800; }
  @media screen and (max-width: 800px) {
    h3 {
      font-size: 3rem; } }
  @media screen and (max-width: 650px) {
    h3 {
      font-size: 2rem; } }

h4 {
  color: white;
  font-size: 1.5rem;
  font-weight: 600; }
  @media screen and (max-width: 650px) {
    h4 {
      font-size: 1rem; } }

html {
  scroll-behavior: smooth; }

.App {
  background-color: #260065;
  text-align: center; }

.underline {
  height: .3rem;
  width: 0%;
  background-color: #11C3CC;
  display: flex;
  margin-bottom: 3rem;
  position: absolute;
  left: 0; }
  @media screen and (max-width: 650px) {
    .underline {
      width: 0%;
      left: 10%; } }

/**
 * Flex Grid -
 * Version: 0.3.5
 *
 * Simple grid built with flex box and sass.
 *
 * Matthew Simo - matthew.a.simo@gmail.com
 */
/**
 * Grid setup
 *
 * The grid will calculate dimensions based on these two variables:
 * $fg-columns will inform the grid loops how many columns there should be.
 * $fg-gutter will inform the grid loops how big eac column's gutters should be.
 *
 * The grid will name columns, rows, offsets based on these three variables:
 * $fg-class-row string used for the row class
 * $fg-class-col string used for the column class
 * $fg-class-off string used for the offset class
 */
/**
 * Break point namespace object
 *
 * Set the default namespace object with these defaults with the
 * understanding that you can pass in whatever you might require for your site.
 *
 * $fg-breakpoints is a Sass list with nested lists inside. Each sub list defines two things.
 * 1. The namespace for that breakpoint. (Required) (i.e. xs, sm, md, lg)
 * 2. The min-width measurement for the breakpoint for that namespace. (i.e. 48em, 62em, 75em)
 *
 * Note: These should be in the proper order (at least till libsass handles map keys properly).
 *
 * Note: If the measurement is left out then it will be skipped when generating
 * the grid and applied to global styles.
 *
 */
/**
 * Class Name Defaults
 *
 * Define class names for columns, rows and offsets in case compatibility with other
 * libraries is necessary.


/**
 * Calculate column size percentage
 */
/**
 * Spacing mixin to create uniform margin/padding
 */
/**
 * Row wrapper class, flex box parent.
 */
.row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.col-xs, .col-sm, .col-md, .col-lg, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.col-xs, .col-sm, .col-md, .col-lg {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

/**
 * Generate a set of grid column classes using a namespace
 *
 * .col-[namespace] for intelligent column division
 * .col-[namespace]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
 * .off-[namespace]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
 * .off-[namespace]-reset for resetting a col's offset for that and larger namespaces
 */
/**
 * Build the grid in two steps, to help minimize file size
 * Step 1, for each namespace, create the grid-base
 * Step 2, for each namespace, wrap the col width/offset measurements in their breakpoint media query
 */
.col-xs-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.off-xs-1 {
  margin-left: 8.33333%; }

.off-xs-2 {
  margin-left: 16.66667%; }

.off-xs-3 {
  margin-left: 25%; }

.off-xs-4 {
  margin-left: 33.33333%; }

.off-xs-5 {
  margin-left: 41.66667%; }

.off-xs-6 {
  margin-left: 50%; }

.off-xs-7 {
  margin-left: 58.33333%; }

.off-xs-8 {
  margin-left: 66.66667%; }

.off-xs-9 {
  margin-left: 75%; }

.off-xs-10 {
  margin-left: 83.33333%; }

.off-xs-11 {
  margin-left: 91.66667%; }

.off-xs-reset {
  margin-left: 0; }

@media only screen and (min-width: 768px) {
  .col-sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-sm-1 {
    margin-left: 8.33333%; }
  .off-sm-2 {
    margin-left: 16.66667%; }
  .off-sm-3 {
    margin-left: 25%; }
  .off-sm-4 {
    margin-left: 33.33333%; }
  .off-sm-5 {
    margin-left: 41.66667%; }
  .off-sm-6 {
    margin-left: 50%; }
  .off-sm-7 {
    margin-left: 58.33333%; }
  .off-sm-8 {
    margin-left: 66.66667%; }
  .off-sm-9 {
    margin-left: 75%; }
  .off-sm-10 {
    margin-left: 83.33333%; }
  .off-sm-11 {
    margin-left: 91.66667%; }
  .off-sm-reset {
    margin-left: 0; } }

@media only screen and (min-width: 992px) {
  .col-md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-md-1 {
    margin-left: 8.33333%; }
  .off-md-2 {
    margin-left: 16.66667%; }
  .off-md-3 {
    margin-left: 25%; }
  .off-md-4 {
    margin-left: 33.33333%; }
  .off-md-5 {
    margin-left: 41.66667%; }
  .off-md-6 {
    margin-left: 50%; }
  .off-md-7 {
    margin-left: 58.33333%; }
  .off-md-8 {
    margin-left: 66.66667%; }
  .off-md-9 {
    margin-left: 75%; }
  .off-md-10 {
    margin-left: 83.33333%; }
  .off-md-11 {
    margin-left: 91.66667%; }
  .off-md-reset {
    margin-left: 0; } }

@media only screen and (min-width: 1200px) {
  .col-lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-lg-1 {
    margin-left: 8.33333%; }
  .off-lg-2 {
    margin-left: 16.66667%; }
  .off-lg-3 {
    margin-left: 25%; }
  .off-lg-4 {
    margin-left: 33.33333%; }
  .off-lg-5 {
    margin-left: 41.66667%; }
  .off-lg-6 {
    margin-left: 50%; }
  .off-lg-7 {
    margin-left: 58.33333%; }
  .off-lg-8 {
    margin-left: 66.66667%; }
  .off-lg-9 {
    margin-left: 75%; }
  .off-lg-10 {
    margin-left: 83.33333%; }
  .off-lg-11 {
    margin-left: 91.66667%; }
  .off-lg-reset {
    margin-left: 0; } }

@-webkit-keyframes splash-height {
  0% {
    min-height: 100vh; }
  100% {
    min-height: 0vh; } }

@keyframes splash-height {
  0% {
    min-height: 100vh; }
  100% {
    min-height: 0vh; } }

@-webkit-keyframes purpleBox {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

@keyframes purpleBox {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes redBox {
  0% {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem); }
  1% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }

@keyframes redBox {
  0% {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem); }
  1% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes blueBox {
  0% {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }

@keyframes blueBox {
  0% {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes dividerLine {
  0% {
    height: 0; }
  100% {
    height: 27rem; } }

@keyframes dividerLine {
  0% {
    height: 0; }
  100% {
    height: 27rem; } }

@-webkit-keyframes dividerLine-Small {
  0% {
    width: 0; }
  100% {
    width: 49rem; } }

@keyframes dividerLine-Small {
  0% {
    width: 0; }
  100% {
    width: 49rem; } }

@-webkit-keyframes cardflip-github {
  0% {
    -webkit-transform: translate(-4.5rem, -6rem) rotateY(90deg);
            transform: translate(-4.5rem, -6rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -6rem) rotateY(0deg);
            transform: translate(-4.5rem, -6rem) rotateY(0deg); } }

@keyframes cardflip-github {
  0% {
    -webkit-transform: translate(-4.5rem, -6rem) rotateY(90deg);
            transform: translate(-4.5rem, -6rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -6rem) rotateY(0deg);
            transform: translate(-4.5rem, -6rem) rotateY(0deg); } }

@-webkit-keyframes cardflip-linkedin {
  0% {
    -webkit-transform: translate(-4.5rem, -7.5rem) rotateY(90deg);
            transform: translate(-4.5rem, -7.5rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -7.5rem) rotateY(0deg);
            transform: translate(-4.5rem, -7.5rem) rotateY(0deg); } }

@keyframes cardflip-linkedin {
  0% {
    -webkit-transform: translate(-4.5rem, -7.5rem) rotateY(90deg);
            transform: translate(-4.5rem, -7.5rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -7.5rem) rotateY(0deg);
            transform: translate(-4.5rem, -7.5rem) rotateY(0deg); } }

@-webkit-keyframes cardflip-linkedin-small {
  0% {
    -webkit-transform: translate(-4.5rem, -7.1rem) rotateY(90deg);
            transform: translate(-4.5rem, -7.1rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -7.1rem) rotateY(0deg);
            transform: translate(-4.5rem, -7.1rem) rotateY(0deg); } }

@keyframes cardflip-linkedin-small {
  0% {
    -webkit-transform: translate(-4.5rem, -7.1rem) rotateY(90deg);
            transform: translate(-4.5rem, -7.1rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -7.1rem) rotateY(0deg);
            transform: translate(-4.5rem, -7.1rem) rotateY(0deg); } }

@-webkit-keyframes cardflip-linkedin-xsmall {
  0% {
    -webkit-transform: translate(-5rem, -6.8rem) rotateY(90deg);
            transform: translate(-5rem, -6.8rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-5rem, -6.8rem) rotateY(0deg);
            transform: translate(-5rem, -6.8rem) rotateY(0deg); } }

@keyframes cardflip-linkedin-xsmall {
  0% {
    -webkit-transform: translate(-5rem, -6.8rem) rotateY(90deg);
            transform: translate(-5rem, -6.8rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-5rem, -6.8rem) rotateY(0deg);
            transform: translate(-5rem, -6.8rem) rotateY(0deg); } }

@-webkit-keyframes cardflip-email {
  0% {
    -webkit-transform: translate(-4.5rem, -8.9rem) rotateY(90deg);
            transform: translate(-4.5rem, -8.9rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -8.9rem) rotateY(0deg);
            transform: translate(-4.5rem, -8.9rem) rotateY(0deg); } }

@keyframes cardflip-email {
  0% {
    -webkit-transform: translate(-4.5rem, -8.9rem) rotateY(90deg);
            transform: translate(-4.5rem, -8.9rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -8.9rem) rotateY(0deg);
            transform: translate(-4.5rem, -8.9rem) rotateY(0deg); } }

@-webkit-keyframes cardflip-email-small {
  0% {
    -webkit-transform: translate(-4.5rem, -8.2rem) rotateY(90deg);
            transform: translate(-4.5rem, -8.2rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -8.2rem) rotateY(0deg);
            transform: translate(-4.5rem, -8.2rem) rotateY(0deg); } }

@keyframes cardflip-email-small {
  0% {
    -webkit-transform: translate(-4.5rem, -8.2rem) rotateY(90deg);
            transform: translate(-4.5rem, -8.2rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-4.5rem, -8.2rem) rotateY(0deg);
            transform: translate(-4.5rem, -8.2rem) rotateY(0deg); } }

@-webkit-keyframes cardflip-email-xsmall {
  0% {
    -webkit-transform: translate(-5.5rem, -7.6rem) rotateY(90deg);
            transform: translate(-5.5rem, -7.6rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-5.5rem, -7.6rem) rotateY(0deg);
            transform: translate(-5.5rem, -7.6rem) rotateY(0deg); } }

@keyframes cardflip-email-xsmall {
  0% {
    -webkit-transform: translate(-5.5rem, -7.6rem) rotateY(90deg);
            transform: translate(-5.5rem, -7.6rem) rotateY(90deg); }
  100% {
    -webkit-transform: translate(-5.5rem, -7.6rem) rotateY(0deg);
            transform: translate(-5.5rem, -7.6rem) rotateY(0deg); } }

@-webkit-keyframes subtitle {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-5deg) translateY(5px);
            transform: rotate(-5deg) translateY(5px); }
  100% {
    opacity: 1;
    -webkit-transform: rotate(-5deg) translateY(0);
            transform: rotate(-5deg) translateY(0); } }

@keyframes subtitle {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-5deg) translateY(5px);
            transform: rotate(-5deg) translateY(5px); }
  100% {
    opacity: 1;
    -webkit-transform: rotate(-5deg) translateY(0);
            transform: rotate(-5deg) translateY(0); } }

@-webkit-keyframes subtitle-xsmall {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-6deg) translateY(5px);
            transform: rotate(-6deg) translateY(5px); }
  100% {
    opacity: 1;
    -webkit-transform: rotate(-6deg) translateY(0);
            transform: rotate(-6deg) translateY(0); } }

@keyframes subtitle-xsmall {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-6deg) translateY(5px);
            transform: rotate(-6deg) translateY(5px); }
  100% {
    opacity: 1;
    -webkit-transform: rotate(-6deg) translateY(0);
            transform: rotate(-6deg) translateY(0); } }

.splash {
  display: flex;
  min-height: 105vh;
  height: 90rem;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  @media screen and (max-width: 650px) {
    .splash {
      height: 50rem; } }
  .splash__animation {
    margin-left: 8rem;
    margin-top: -19rem;
    /* L Specific Timing*/
    /* W Specific Timing*/
    /* I Specific Timing*/
    /* N Specific Timing*/ }
    @media screen and (max-width: 650px) {
      .splash__animation {
        margin-left: 11rem; } }
    .splash__animation .letter {
      margin-top: 10rem;
      width: 21rem;
      height: 17rem;
      overflow: visible;
      margin-left: -10rem; }
      @media screen and (max-width: 650px) {
        .splash__animation .letter {
          width: 15rem;
          height: 8rem;
          margin-left: -10rem; } }
    .splash__animation .icon {
      width: 11rem;
      height: 11rem;
      overflow: visible;
      cursor: pointer; }
      @media screen and (max-width: 1100px) {
        .splash__animation .icon {
          width: 8rem;
          height: 8rem; } }
      @media screen and (max-width: 650px) {
        .splash__animation .icon {
          width: 6rem;
          height: 6rem; } }
    .splash__animation .letter-l {
      -webkit-transform: translate(-0.9rem, -1rem);
              transform: translate(-0.9rem, -1rem); }
      @media screen and (max-width: 650px) {
        .splash__animation .letter-l {
          -webkit-transform: translate(-0.4rem, -0.5rem);
                  transform: translate(-0.4rem, -0.5rem); } }
    .splash__animation .letter-w {
      height: 17.9rem;
      -webkit-transform: translate(-1.6rem, -1.8rem);
              transform: translate(-1.6rem, -1.8rem); }
      @media screen and (max-width: 650px) {
        .splash__animation .letter-w {
          height: 8.2rem;
          -webkit-transform: translate(-0.7rem, -1.1rem);
                  transform: translate(-0.7rem, -1.1rem); } }
    .splash__animation .letter-i {
      height: 17.2rem;
      -webkit-transform: translate(-2rem, -3.1rem);
              transform: translate(-2rem, -3.1rem); }
      @media screen and (max-width: 650px) {
        .splash__animation .letter-i {
          height: 7.8rem;
          -webkit-transform: translate(-1rem, -1.7rem);
                  transform: translate(-1rem, -1.7rem); } }
    .splash__animation .letter-n {
      height: 18rem;
      -webkit-transform: translate(-5.2rem, -3.4rem);
              transform: translate(-5.2rem, -3.4rem); }
      @media screen and (max-width: 650px) {
        .splash__animation .letter-n {
          height: 8rem;
          -webkit-transform: translate(-2.4rem, -1.9rem);
                  transform: translate(-2.4rem, -1.9rem); } }
    .splash__animation #box-purple {
      -webkit-animation: purpleBox 0.3s 0.6s ease-out forwards;
              animation: purpleBox 0.3s 0.6s ease-out forwards;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      opacity: 0; }
    .splash__animation #box-red {
      -webkit-animation: redBox 0.5s ease-out forwards;
              animation: redBox 0.5s ease-out forwards;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      opacity: 0; }
    .splash__animation #box-blue {
      -webkit-animation: blueBox 0.5s 0.25s ease-out forwards;
              animation: blueBox 0.5s 0.25s ease-out forwards;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      opacity: 0; }
    .splash__animation #box-shadow {
      -webkit-animation: fadeIn 0.5s 0.6s ease-out forwards;
              animation: fadeIn 0.5s 0.6s ease-out forwards;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      opacity: 0; }
    .splash__animation #box-innerpink {
      -webkit-animation: redBox 0.5s 0.25s ease-out forwards;
              animation: redBox 0.5s 0.25s ease-out forwards;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      opacity: 0; }
    .splash__animation #box-pink {
      -webkit-animation: redBox 0.5s 0.25s ease-out forwards;
              animation: redBox 0.5s 0.25s ease-out forwards;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      opacity: 0; }
    .splash__animation #icon {
      -webkit-animation: fadeIn 0.5s 1.1s ease-out forwards;
              animation: fadeIn 0.5s 1.1s ease-out forwards;
      opacity: 0; }
    .splash__animation .letter-l #box-purple {
      -webkit-animation: purpleBox 0.3s 0.7s ease-out forwards;
              animation: purpleBox 0.3s 0.7s ease-out forwards; }
    .splash__animation .letter-l #box-red {
      -webkit-animation: redBox 0.5s 0.1s ease-out forwards;
              animation: redBox 0.5s 0.1s ease-out forwards; }
    .splash__animation .letter-l #box-blue {
      -webkit-animation: blueBox 0.5s 0.35s ease-out forwards;
              animation: blueBox 0.5s 0.35s ease-out forwards; }
    .splash__animation .letter-l #box-shadow {
      -webkit-animation: fadeIn 0.5s 0.7s ease-out forwards;
              animation: fadeIn 0.5s 0.7s ease-out forwards; }
    .splash__animation .letter-l #box-innerpink {
      -webkit-animation: redBox 0.5s 0.35s ease-out forwards;
              animation: redBox 0.5s 0.35s ease-out forwards; }
    .splash__animation .letter-l #box-pink {
      -webkit-animation: redBox 0.5s 0.35s ease-out forwards;
              animation: redBox 0.5s 0.35s ease-out forwards; }
    .splash__animation .letter-w #box-purple {
      -webkit-animation: purpleBox 0.3s 0.8s ease-out forwards;
              animation: purpleBox 0.3s 0.8s ease-out forwards; }
    .splash__animation .letter-w #box-red {
      -webkit-animation: redBox 0.5s 0.2s ease-out forwards;
              animation: redBox 0.5s 0.2s ease-out forwards; }
    .splash__animation .letter-w #box-blue {
      -webkit-animation: blueBox 0.5s 0.45s ease-out forwards;
              animation: blueBox 0.5s 0.45s ease-out forwards; }
    .splash__animation .letter-w #box-shadow {
      -webkit-animation: fadeIn 0.5s 0.8s ease-out forwards;
              animation: fadeIn 0.5s 0.8s ease-out forwards; }
    .splash__animation .letter-w #box-innerpink {
      -webkit-animation: redBox 0.5s 0.45s ease-out forwards;
              animation: redBox 0.5s 0.45s ease-out forwards; }
    .splash__animation .letter-w #box-pink {
      -webkit-animation: redBox 0.5s 0.45s ease-out forwards;
              animation: redBox 0.5s 0.45s ease-out forwards; }
    .splash__animation .letter-i #box-purple {
      -webkit-animation: purpleBox 0.3s 0.9s ease-out forwards;
              animation: purpleBox 0.3s 0.9s ease-out forwards; }
    .splash__animation .letter-i #box-red {
      -webkit-animation: redBox 0.5s 0.3s ease-out forwards;
              animation: redBox 0.5s 0.3s ease-out forwards; }
    .splash__animation .letter-i #box-blue {
      -webkit-animation: blueBox 0.5s 0.55s ease-out forwards;
              animation: blueBox 0.5s 0.55s ease-out forwards; }
    .splash__animation .letter-i #box-shadow {
      -webkit-animation: fadeIn 0.5s 0.9s ease-out forwards;
              animation: fadeIn 0.5s 0.9s ease-out forwards; }
    .splash__animation .letter-i #box-innerpink {
      -webkit-animation: redBox 0.5s 0.55s ease-out forwards;
              animation: redBox 0.5s 0.55s ease-out forwards; }
    .splash__animation .letter-i #box-pink {
      -webkit-animation: redBox 0.5s 0.55s ease-out forwards;
              animation: redBox 0.5s 0.55s ease-out forwards; }
    .splash__animation .letter-n #box-purple {
      -webkit-animation: purpleBox 0.3s 1s ease-out forwards;
              animation: purpleBox 0.3s 1s ease-out forwards; }
    .splash__animation .letter-n #box-red {
      -webkit-animation: redBox 0.5s 0.4s ease-out forwards;
              animation: redBox 0.5s 0.4s ease-out forwards; }
    .splash__animation .letter-n #box-blue {
      -webkit-animation: blueBox 0.5s 0.65s ease-out forwards;
              animation: blueBox 0.5s 0.65s ease-out forwards; }
    .splash__animation .letter-n #box-shadow {
      -webkit-animation: fadeIn 0.5s 1s ease-out forwards;
              animation: fadeIn 0.5s 1s ease-out forwards; }
    .splash__animation .letter-n #box-innerpink {
      -webkit-animation: redBox 0.5s 0.365s ease-out forwards;
              animation: redBox 0.5s 0.365s ease-out forwards; }
    .splash__animation .letter-n #box-pink {
      -webkit-animation: redBox 0.5s 0.65s ease-out forwards;
              animation: redBox 0.5s 0.65s ease-out forwards; }
  .splash .divider-line {
    height: 0;
    width: 0.3rem;
    background-color: #11C3CC;
    display: inline-block;
    position: absolute;
    margin-top: 5rem;
    -webkit-animation: dividerLine 0.3s 1s ease-out forwards;
            animation: dividerLine 0.3s 1s ease-out forwards;
    margin-left: -2.5rem; }
    @media screen and (max-width: 1100px) {
      .splash .divider-line {
        display: none; } }
  .splash__icons {
    display: inline-block; }
    .splash__icons .icon-github {
      display: inline-block;
      margin-left: 10rem;
      -webkit-transform: translate(-4.5rem, -6rem) rotateY(90deg);
              transform: translate(-4.5rem, -6rem) rotateY(90deg);
      -webkit-animation: cardflip-github 0.5s 1.3s ease-out forwards;
              animation: cardflip-github 0.5s 1.3s ease-out forwards; }
    .splash__icons .icon-linkedin {
      margin-left: 1rem;
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
      -webkit-transform: translate(-4.5rem, -7.5rem) rotateY(90deg);
              transform: translate(-4.5rem, -7.5rem) rotateY(90deg);
      -webkit-animation: cardflip-linkedin 0.5s 1.5s ease-out forwards;
              animation: cardflip-linkedin 0.5s 1.5s ease-out forwards; }
    .splash__icons .icon-email {
      margin-left: 10px;
      -webkit-transform: translate(-4.5rem, -8.9rem);
              transform: translate(-4.5rem, -8.9rem);
      -webkit-transform: translate(-4.5rem, -8.9rem) rotateY(90deg);
              transform: translate(-4.5rem, -8.9rem) rotateY(90deg);
      -webkit-animation: cardflip-email 0.5s 1.7s ease-out forwards;
              animation: cardflip-email 0.5s 1.7s ease-out forwards; }
    @media screen and (max-width: 1100px) {
      .splash__icons {
        margin-top: 10rem;
        margin-left: 3.5rem;
        width: 100%; }
        .splash__icons .icon-linkedin {
          -webkit-animation: cardflip-linkedin-small 0.5s 1.5s ease-out forwards;
                  animation: cardflip-linkedin-small 0.5s 1.5s ease-out forwards; }
        .splash__icons .icon-email {
          -webkit-animation: cardflip-email-small 0.5s 1.7s ease-out forwards;
                  animation: cardflip-email-small 0.5s 1.7s ease-out forwards; } }
    @media screen and (max-width: 650px) {
      .splash__icons {
        margin-top: 8rem;
        margin-left: -9rem;
        width: 125%; }
        .splash__icons .icon-linkedin {
          -webkit-animation: cardflip-linkedin-xsmall 0.5s 1.5s ease-out forwards;
                  animation: cardflip-linkedin-xsmall 0.5s 1.5s ease-out forwards; }
        .splash__icons .icon-email {
          -webkit-animation: cardflip-email-xsmall 0.5s 1.7s ease-out forwards;
                  animation: cardflip-email-xsmall 0.5s 1.7s ease-out forwards; } }
  .splash__subtitle {
    color: #FFC0D7;
    font-weight: bold;
    letter-spacing: 0.6rem;
    font-size: 2rem;
    text-transform: uppercase;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    margin-top: -1rem;
    margin-left: -44rem;
    opacity: 0;
    -webkit-animation: subtitle 0.6s 2s linear forwards;
            animation: subtitle 0.6s 2s linear forwards;
    font-weight: 700;
    margin-bottom: 0; }
    @media screen and (max-width: 1100px) {
      .splash__subtitle {
        margin-left: 0;
        margin-top: -21rem; } }
    @media screen and (max-width: 650px) {
      .splash__subtitle {
        margin-left: -5.6rem;
        margin-top: -16rem;
        font-size: 1rem;
        letter-spacing: 0.2rem;
        -webkit-animation: subtitle-xsmall 0.6s 2s linear forwards;
                animation: subtitle-xsmall 0.6s 2s linear forwards; } }

.projects {
  padding-bottom: 10rem;
  position: relative;
  width: 100rem;
  max-width: 90%;
  margin: 0 auto; }
  .projects__cards {
    padding-top: 4rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 auto; }
    .projects__cards .projectcard {
      cursor: pointer;
      width: 45%;
      display: block;
      margin: 1rem;
      position: relative;
      -webkit-transition: opacity 0.6s 0.6s, -webkit-transform 0.2s;
      transition: opacity 0.6s 0.6s, -webkit-transform 0.2s;
      transition: transform 0.2s, opacity 0.6s 0.6s;
      transition: transform 0.2s, opacity 0.6s 0.6s, -webkit-transform 0.2s; }
      .projects__cards .projectcard:hover {
        -webkit-transform: translate(0.2rem, 0.2rem);
                transform: translate(0.2rem, 0.2rem); }
      .projects__cards .projectcard img {
        width: 100%; }
      .projects__cards .projectcard h3 {
        position: absolute;
        left: 3rem;
        bottom: 3rem; }
      .projects__cards .projectcard h4 {
        position: absolute;
        left: 3rem;
        bottom: 8rem;
        opacity: 0.8; }
      @media screen and (max-width: 800px) {
        .projects__cards .projectcard h4 {
          bottom: 6.5rem; } }
      @media screen and (max-width: 650px) {
        .projects__cards .projectcard {
          width: 70%;
          margin: 0.5rem; }
          .projects__cards .projectcard h3 {
            bottom: 2rem;
            left: 2rem; }
          .projects__cards .projectcard h4 {
            bottom: 4.2rem;
            left: 2rem; } }
  .projects--active h2 {
    opacity: 1;
    -webkit-transition: opacity 2s;
    transition: opacity 2s; }
  .projects--active .underline {
    width: 100%;
    -webkit-transition: width 1s;
    transition: width 1s; }
    @media screen and (max-width: 650px) {
      .projects--active .underline {
        width: 80%; } }
  .projects--active .projectcard {
    opacity: 1; }
  .projects--active .projects__description {
    opacity: 1; }
  .projects__description {
    -webkit-transition: opacity 0.6s 1s;
    transition: opacity 0.6s 1s; }
    .projects__description h2 {
      margin-top: 2rem; }
    .projects__description p {
      color: white;
      text-align: left;
      font-weight: 400;
      font-size: 2rem; }
      @media screen and (max-width: 650px) {
        .projects__description p {
          width: 80%;
          font-size: 1.6rem; } }
      .projects__description p a {
        color: #11C3CC !important; }

.Modal {
  background-color: #260065;
  max-width: calc(90% - 8rem);
  width: 92rem;
  margin: 0 auto;
  padding: 4rem;
  border-radius: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  outline: none;
  max-height: calc(90% - 8rem); }
  @media screen and (max-width: 650px) {
    .Modal {
      overflow-y: scroll; } }
  .Modal button {
    background-color: #8D05C6;
    border: none;
    color: white;
    font-size: 2rem;
    margin-top: 2rem;
    padding: 1rem 3rem;
    border-radius: 2rem;
    cursor: pointer;
    outline: none; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  display: flex;
  align-items: center; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.languages {
  padding-bottom: 10rem;
  position: relative;
  width: 100rem;
  max-width: 90%;
  margin: 0 auto; }
  .languages h2 {
    opacity: 0; }
    @media screen and (max-width: 650px) {
      .languages h2 {
        max-width: 80%;
        margin: 0 auto; } }
  .languages__cards {
    padding-top: 4rem; }
    @media screen and (max-width: 650px) {
      .languages__cards {
        max-width: 80%;
        margin: 0 auto; } }
  .languages svg {
    opacity: 0;
    width: 12rem;
    margin: 0 1rem;
    cursor: pointer;
    -webkit-transition: opacity 2s 2s, -webkit-transform .2s;
    transition: opacity 2s 2s, -webkit-transform .2s;
    transition: transform .2s, opacity 2s 2s;
    transition: transform .2s, opacity 2s 2s, -webkit-transform .2s; }
    .languages svg:hover {
      -webkit-transform: translate(0.2rem, 0.2rem);
              transform: translate(0.2rem, 0.2rem); }
    @media screen and (max-width: 400px) {
      .languages svg {
        width: calc(45% - 2rem); } }
  .languages--active h2 {
    opacity: 1;
    -webkit-transition: opacity 2s 1s;
    transition: opacity 2s 1s; }
  .languages--active .underline {
    width: 100%;
    -webkit-transition: width 1s 1s;
    transition: width 1s 1s; }
    @media screen and (max-width: 650px) {
      .languages--active .underline {
        width: 80%; } }
  .languages--active svg {
    opacity: 1; }

.about {
  position: relative;
  width: 100rem;
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 10rem;
  margin-top: -25rem; }
  .about h2 {
    opacity: 0; }
  .about p {
    text-align: left;
    opacity: 0;
    padding-top: 4rem;
    color: white;
    font-weight: 400;
    font-size: 2rem; }
    @media screen and (max-width: 650px) {
      .about p {
        max-width: 80%;
        margin: 0 auto;
        font-size: 1.6rem; } }
  .about--active h2 {
    opacity: 1;
    -webkit-transition: opacity 2s;
    transition: opacity 2s; }
  .about--active .underline {
    width: 100%;
    -webkit-transition: width 1s;
    transition: width 1s; }
    @media screen and (max-width: 650px) {
      .about--active .underline {
        width: 80%; } }
  .about--active p {
    opacity: 1;
    -webkit-transition: opacity 2s;
    transition: opacity 2s; }
    .about--active p a {
      color: white; }

.contact {
  padding-bottom: 10rem;
  position: relative;
  width: 100rem;
  max-width: 90%;
  margin: 0 auto; }
  .contact h2 {
    opacity: 0; }
    @media screen and (max-width: 650px) {
      .contact h2 {
        max-width: 80%;
        margin: 0 auto; } }
  .contact svg {
    opacity: 0;
    width: 12rem;
    margin: 0 1rem;
    cursor: pointer;
    -webkit-transition: opacity 2s 2s, -webkit-transform .2s;
    transition: opacity 2s 2s, -webkit-transform .2s;
    transition: transform .2s, opacity 2s 2s;
    transition: transform .2s, opacity 2s 2s, -webkit-transform .2s; }
    .contact svg:hover {
      -webkit-transform: translate(0.2rem, 0.2rem);
              transform: translate(0.2rem, 0.2rem); }
    @media screen and (max-width: 400px) {
      .contact svg {
        width: calc(45% - 2rem); } }
  .contact--active h2 {
    opacity: 1;
    -webkit-transition: opacity 2s 1s;
    transition: opacity 2s 1s; }
  .contact--active .underline {
    width: 100%;
    -webkit-transition: width 1s 1s;
    transition: width 1s 1s; }
    @media screen and (max-width: 650px) {
      .contact--active .underline {
        width: 80%; } }
  .contact--active svg {
    opacity: 1; }
  .contact__inner {
    margin-top: 5rem; }

