.languages {
  padding-bottom: 10rem;
  position: relative;
  width: 100rem;
  max-width: 90%;
  margin: 0 auto;

  h2 {
    opacity: 0;

    @media screen and (max-width: 650px){
      max-width: 80%;
      margin: 0 auto;
    }
  }

  
  &__cards {
    padding-top: 4rem;
  
    @media screen and (max-width: 650px){
      max-width: 80%;
      margin: 0 auto;
    }
  }
  
  svg {
    opacity: 0;
    width: 12rem;
    margin: 0 1rem;
    cursor: pointer;
    transition: transform .2s, opacity 2s 2s;

    &:hover {
      transform: translate(.2rem, .2rem);
    }

    @media screen and (max-width: 400px){
      width: calc(45% - 2rem);
    }
  }

  &--active {
    h2 {
      opacity: 1;
      transition: opacity 2s 1s;
    }

    .underline {
      width: 100%;
      transition: width 1s 1s;

      @media screen and (max-width: 650px){
        width: 80%;
      }
    }

    svg {
      opacity: 1;
    }
  }
}