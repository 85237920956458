.projects {
  padding-bottom: 10rem;
  position: relative;
  width: 100rem;
  max-width: 90%;
  margin: 0 auto;

  &__cards {
    padding-top: 4rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 auto;

    .projectcard {
      cursor: pointer;
      width: 45%;
      display: block;
      margin: 1rem;
      position: relative;
      transition: transform 0.2s, opacity 0.6s 0.6s;

      &:hover {
        transform: translate(0.2rem, 0.2rem);
      }

      img {
        width: 100%;
      }

      h3 {
        position: absolute;
        left: 3rem;
        bottom: 3rem;
      }

      h4 {
        position: absolute;
        left: 3rem;
        bottom: 8rem;
        opacity: 0.8;
      }

      @media screen and (max-width: 800px) {
        h4 {
          bottom: 6.5rem;
        }
      }

      @media screen and (max-width: 650px) {
        width: 70%;
        margin: 0.5rem;

        h3 {
          bottom: 2rem;
          left: 2rem;
        }

        h4 {
          bottom: 4.2rem;
          left: 2rem;
        }
      }
    }
  }

  &--active {
    h2 {
      opacity: 1;
      transition: opacity 2s;
    }

    .underline {
      width: 100%;
      transition: width 1s;

      @media screen and (max-width: 650px) {
        width: 80%;
      }
    }

    .projectcard {
      opacity: 1;
    }

    .projects__description {
      opacity: 1;
    }
  }

  &__description {
    transition: opacity 0.6s 1s;

    h2 {
      margin-top: 2rem;
    }

    p {
      color: white;
      text-align: left;
      font-weight: 400;
      font-size: 2rem;

      @media screen and (max-width: 650px) {
        width: 80%;
        font-size: 1.6rem;
      }

      a {
        color: $color-secondary !important;
      }
    }
  }
}

.Modal {
  background-color: $color-background;
  max-width: calc(90% - 8rem);
  width: 92rem;
  margin: 0 auto;
  padding: 4rem;
  border-radius: 2rem;
  height: fit-content;
  border: none;
  outline: none;
  max-height: calc(90% - 8rem);

  @media screen and (max-width: 650px) {
    overflow-y: scroll;
  }

  button {
    background-color: $color-quaternary;
    border: none;
    color: white;
    font-size: 2rem;
    margin-top: 2rem;
    padding: 1rem 3rem;
    border-radius: 2rem;
    cursor: pointer;
    outline: none;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  display: flex;
  align-items: center;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
